.MuiTablePagination-displayedRows {
  display: none;
}

.MuiModal-root .MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.5) !important;
}

.MuiModal-root.MuiPopover-root .MuiBackdrop-root {
  background: transparent !important;
}

.swal2-container {
  z-index: 1200 !important;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > div > h6.MuiTypography-root.MuiTypography-h6 {
  color: #000 !important;
}

.error-msg {
  color: #ff4842;
  font-size: 0.75rem;
  margin-left: 14px;
  margin-top: 8px;
}


.hyperlink{
  color: #205ad5;
}


.dash-board-box{
  background: #f1f3f5;
  padding: 25px;
  border-radius: 16px;
}
.dash-board-box h4{
  font-size: 20px;
  font-weight: 100;
}

.dash-board-box h2{
  color: #374cce;
  font-weight: 100;
  font-size: 18px;
}


.main-loader{
  position: absolute;
    width: 100%;
    height: 100%;
}


.notification-blog{position:absolute;top:50px;right:0;background:#fff;border-radius:6px;box-shadow:0 1px 9px rgb(64 58 58 / 6%);min-width: 460px;padding:25px 25px;height:410px;overflow-y:auto;overflow-x:hidden}
.notification-blog-head h4{font-size:18px;color:#000;font-weight:700; }
.notification-blog-head{margin-bottom:20px}
.notification-list-title{font-size:15px;display:flex;align-items:center;justify-content:space-between;padding-bottom:10px}
.notification-list-title small{font-size:14px;color:#a4a7b9}
.notification-list li p{font-size:14px;color:#000}
.notification-list li{border-bottom:1px solid #e4e4e4;padding-bottom:15px;margin-bottom:15px}
.notification-box{position:relative; cursor: pointer;}
.notification-list-title h4{font-size:15px;font-weight:700;width: 250px; color: black;}
.notification-list li:last-child{border-bottom:0;margin-bottom:0;padding-bottom:0}


.title-noti-head small {
  color: rgb(0 0 0 / 32%);
  font-size: 12px;
}
.title-noti-head h6 {
  font-size: 16px;
}
ul.notification-list li p {
  font-size: 13px;
  width: 70%;
}
.notification-list h5 {
  font-size: 18px;
}

.notifications-btn {
  position: relative;
}
small.noti-content {
  position: absolute;
  right: -6px;
  top: -11px;
  background: #c62525;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 9px;
  font-weight: 700;
}
ul.notification-list li {
  margin-bottom: 5px;
  padding-bottom: 10px !IMPORTANT;
}
.title-noti-head h6{margin-bottom: 0;}

.title-noti-head {
  margin-bottom: 4px;
}
@media(max-width:480px){

.notification-list {
  width: 100% !IMPORTANT;
  right: 0 !important;
}
}


.notification-dismiss{
  font-size: 14px;
}




.MuiPopover-paper{
  top: 220px ;
  max-width: 255px;
    max-height: 194px;
    left: 656px;
}
.css-yaqc1m-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  margin-left: -9px;
}
.text-center{ text-align: center; font-size: 22px; margin-bottom: 24px; font-weight:700};

.customdesign-description {
  margin-right: 19%;
}



.css-1p2eak-MuiCardMedia-root{
  border-radius: 5px;
  max-height: 500px;
  max-width: 500px;
  width: auto;
  height: auto;
  max-width: 70%;
}

.css-yig3w1-MuiStack-root{
  display: flex;
  justify-content: flex-end;
}
